.main-footer {
    background-color: #1e1e20;
    color: white;
    font-size: 14px;
    height: 59px;
    position: fixed;
    width: 100%;

    strong {
        margin-left: 1.5rem;
    }
}